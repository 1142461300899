import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './Pocus.css';


function Pocus() {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <div className="CS_Pocus_container">

      {/* Section 1: Back Arrow */}
      <div className="CS_Pocus_backicon">
      <div className='back-arrow' onClick={handleBackClick}>
          <FontAwesomeIcon icon={faArrowLeft} /> 
        </div>
      </div>

      {/* Section 2: Title EASyWork and Subtitle */}
      <div className="CS_Pocus_Header">
        Pocus
      </div>

      <div className="CS_Pocus_Description">The client from Sweden 🇸🇪 had a concept to repurpose the data generated by sonography machines 📺 to create quizzes for junior doctors 🩺, enabling them to practice and enhance their skills using diverse datasets.
       </div>

      {/* Section 3: Header Image includes 3 wireframe*/}
      <div className="CS_Pocus_HeaderImage">
       <img
    src={`${process.env.PUBLIC_URL}/Pocus02.gif`}
    alt="Description of Image"
    className="CS_Pocus_HeaderImage__img"/>
      </div>

      {/* Section 4: My role and Responsibility with time duration and tools */}
      <div className="CS_Pocus_info_bg"></div>

      <div className="CS_Pocus_section_left">
        {/* Left Column - Overview */}
        <div className="CS_Pocus_TitleAndDescriptionGroup">
          <div className="CS_Pocus_info_Title">My Role</div>
          <div className="CS_Pocus_info_Description">
          UX Researcher, User Flows, Paper Prototyping, Wireframes. 
          </div>
        </div>

        <div className="CS_Pocus_TitleAndDescriptionGroup">
          <div className="CS_Pocus_info_Title">Tools</div>
          <div className="CS_Pocus_info_Description">
            Figma, Zeplin, Mindmap. 
          </div>
        </div>

        <div className="CS_Pocus_TitleAndDescriptionGroup">
          <div className="CS_Pocus_info_Title">Client Location</div>
          <div className="CS_Pocus_info_Description">
            Sweden 🇸🇪 
          </div>
        </div>


      </div>

      <div className="CS_Pocus_section_right">
        {/* Right Column - Overview */}
        <div className="CS_Pocus_TitleAndDescriptionGroup">
          <div className="CS_Pocus_info_Title">Duration</div>
          <div className="CS_Pocus_info_Description">
            6 Week
          </div>
        </div>

        <div className="CS_Pocus_TitleAndDescriptionGroup">
          <div className="CS_Pocus_info_Title">Date</div>
          <div className="CS_Pocus_info_Description">
            Oct 2022 - Nov 2022
          </div>
        </div>
      </div>

      {/* Section 5: Design Challange   */}
      <div className="CS_Pocus_Header_Section5">
      Design Challange 
      </div>

      <div className="CS_Pocus_Description_Section5">
        <div className="CS_Pocus_BodyHeader">
        Transforming Medical Visual Data into a Learning Tool for Trainee Doctors
        </div>
        <p className='CS_Pocus_BodyText'>
        Every day, <span class="CS_Pocus_BodyText_Highlight"> medical facilities generate vast amounts of visual diagnostic data,</span> such as ultrasound images and videos. Once used for patient diagnostics, this data often becomes redundant and remains underutilized. The challenge was to <span class="CS_Pocus_BodyText_Highlight">repurpose this valuable resource into an opportunity </span> for trainee doctors and medical students to learn and practice real-world diagnostic skills.
        </p>
        <p className='CS_Pocus_BodyText'>

        The goal was to design a product that uses this data to create interactive MCQs, enabling students to engage with authentic visuals rather than relying solely on static images found in textbooks or traditional learning methods. This innovative approach aimed to <span class="CS_Pocus_BodyText_Highlight">bridge the gap between theoretical knowledge and practical application, fostering a more immersive and effective learning experience.</span>
          </p>
      </div>

  


      {/* Section 6  (Old8): Design Process */}

      <div className='CS_Pocus_Section7_Text_Left'>
        Design Process
        </div>
        <div className='CS_Pocus_Section7_Text_Right'>
            <p className='CS_Pocus_BodyText'>Initially, I approached the project using the <span class="CS_Pocus_BodyText_Highlight">design thinking process</span>, but later, the approach was <span class="CS_Pocus_BodyText_Highlight">customized to meet specific needs </span>. The client provided a well-organized set of requirements, and after two discussions, I created an app mind map, which was reviewed and confirmed by the client. 
            </p>
            <p className='CS_Pocus_BodyText'>
            Next, I created low-fidelity wireframes and prototypes with the primary goal of finalizing the app's flow and functionality. This process allowed the client to provide feedback, and after several iterations, we finalized the design. <span class="CS_Pocus_BodyText_Highlight">Once the flow and functionality were confirmed, I shifted focus to aesthetics and accessibility. </span> I shared sample high-fidelity themes for the client to choose from. The client prioritized a design that was easy to develop, <span class="CS_Pocus_BodyText_Highlight">considering development constraints </span>.Finally, I created production-ready screens and delivered the handoff to the client.</p>
      </div> 
    {/* section 9 (Old 10) : User Flow */}

    <div className="CS_Pocus_Header_Section10">
    User Flow
      </div>

      <div className="CS_Pocus_Description_Section10">
      <p className='CS_Pocus_BodyText'>One basic flow was finalized with the client to define the product flow according to the requirements he provided, allowing the process to kick-start. (Please ignore the black background color, as it was initially designed for a dark theme to align better with the ultrasound machine interface.)
      </p>
      </div>


       
    {/* Section 10 (Old 11) : dashboard image  */}
    <div className="CS_Pocus_Section9_HeaderImage">  
    <img
    src={`${process.env.PUBLIC_URL}/PocusMindmap.jpg`}
    alt="Description of Image"
    className="CS_Pocus_Section9_HeaderImage__img"/>
    </div>


  {/* Section 11 :ABout phase 1 results  */}

    <div className="CS_Pocus_Header_Section10_2">
    Wireframes
      </div>

      <div className="CS_Pocus_Description_Section10_2">
      <p className='CS_Pocus_BodyText'>
      The following are some of the deliverables provided to the client, including both low-fidelity and high-fidelity designs, to finalize the product.      </p>
      </div>


 {/* Section 12 : Lofi image  */}
    <div className="CS_Pocus_Section12_HeaderImage">  
    <div className='CS_Pocus_image2_Description '>
     📌 Here are a few from the low-fidelity wireframes, showcasing the initial layout and structure of the design, which emphasize functionality and basic user flow without focusing on visual aesthetics and accessibility.
    </div>
    <img
    src={`${process.env.PUBLIC_URL}/lofi12.png`}
    alt="Description of Image"
    className="CS_Pocus_Section12_HeaderImage__img"/>
    </div>


   {/* Section 13 : HiFi Options  */}
   <div className="CS_Pocus_Section13_HeaderImage">  
   <div className='CS_Pocus_image2_Description '>
   📌 Some options were provided to the client, primarily suitable for black screens, as they closely align with medical equipment and sonography machines. The last option among these was finalized by the client.   </div>
   <img
   src={`${process.env.PUBLIC_URL}/PocusHiFI01.png`}
   alt="Description of Image"
   className="CS_Pocus_Section12_HeaderImage__img"/>

<img
   src={`${process.env.PUBLIC_URL}/PocusHiFI02.png`}
   alt="Description of Image"
   className="CS_Pocus_Section12_HeaderImage__img"/>


<img
   src={`${process.env.PUBLIC_URL}/PocusHiFI03.png`}
   alt="Description of Image"
   className="CS_Pocus_Section12_HeaderImage__img"/>


<img
   src={`${process.env.PUBLIC_URL}/PocusHiFI04.png`}
   alt="Description of Image"
   className="CS_Pocus_Section12_HeaderImage__img"/>
   </div>
   





   {/* Section 14 : Final HiFi   */}
   <div className="CS_Pocus_Section14_HeaderImage">  
   <div className='CS_Pocus_image2_Description '>
    📌 The client specifically mentioned having development constraints, so a simple design was necessary. Below are a few wireframes from the final designs.
   </div>
   <img
   src={`${process.env.PUBLIC_URL}/PocusHiFI05.png`}
   alt="Description of Image"
   className="CS_Pocus_Section12_HeaderImage__img"/>

<img
   src={`${process.env.PUBLIC_URL}/PocusHiFI06.png`}
   alt="Description of Image"
   className="CS_Pocus_Section12_HeaderImage__img"/>


<img
   src={`${process.env.PUBLIC_URL}/PocusHiFI07.png`}
   alt="Description of Image"
   className="CS_Pocus_Section12_HeaderImage__img"/>


<img
   src={`${process.env.PUBLIC_URL}/PocusHiFI08.png`}
   alt="Description of Image"
   className="CS_Pocus_Section12_HeaderImage__img"/>

  
<img
   src={`${process.env.PUBLIC_URL}/PocusHiFI09.gif`}
   alt="Description of Image"
   className="CS_Pocus_Section12_HeaderImage__img"/>
   
<img
   src={`${process.env.PUBLIC_URL}/PocusHiFI10.png`}
   alt="Description of Image"
   className="CS_Pocus_Section12_HeaderImage__img"/> 
   
   

  </div>

</div>

  );
}

export default Pocus;
